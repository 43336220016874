<template>
  <Modal nome="criarSecaoComIa">
    <div class="chat" :class="{ criando: state.mostrarMensagem }">
      <div class="enviar">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <textarea placeholder="Mensagem para a UnicPages" v-model="state.informacoes" v-redimensionar-textarea></textarea>
        <div class="carregando">
          <span></span>
        </div>
        <button @click="pedirParaIa()" :class="{ ativo: state.mensagem }">
          <Svgs nome="seta" />
        </button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { inject, reactive } from 'vue'
import { useStoreCriar, useStoreAjustes, useStoreModal, useStorePerfil, useStoreGpt } from '@stores'
import { helperId } from '@helpers'
import { useStoreSecaoComIa } from '@components/criar/secoes/codigo/secaoComIa.js'
import Svgs from '@svgs'

import Modal from '@components/global/modal/Modal.vue'

const emitter = inject('emitter')
const storeGpt = useStoreGpt()
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()
const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const storeSecaoComIa = useStoreSecaoComIa()

const state = reactive({
  informacoes: '',
  mostrarMensagem: false
})

function adicionarSecao(secao, storeFunc) {
  const id = helperId.random(12)
  const store = sanitizarStore(storeFunc())

  storeCriar.adicionarSecao(secao, id, true)
  storeAjustes.adicionarSecao(secao, id, store, true)
  emitter.emit('renderizarComponentesTela')
  emitter.emit('atualizarCamadasSite')
  state.informacoes = ''
  state.mostrarMensagem = false
  storeModal.fecharModal('criarSecaoComIa')
}

function sanitizarStore(store) {
  const clone = { ...store }

  for (const key in clone) {
    if (key.startsWith('_')) delete clone[key]
    if (key.startsWith('$')) delete clone[key]
  }

  return JSON.parse(JSON.stringify(clone))
}

function criarSecao() {
  adicionarSecao('Html2Desktop', useStoreSecaoComIa)
}

async function pedirParaIa() {
  try {
    state.mostrarMensagem = true
    const mensagem = `

Informações que meu cliente enviou: ${state.informacoes}

Preciso seguir essas instruções para criar 1 seção com HTML, CSS e JS 100% puro, seguindo as regras abaixo:

Regras do HTML
	1.	Use apenas <section class="secaoNome">. Não adicione <head>, <body>, <script>, <link> ou importações externas.
	2.	Utilize tags semânticas (h1, h2, p, figure, button, a) para estruturar bem o conteúdo.
	3.	Adicione comentários explicativos nos elementos principais.
	4.	Somente se necessário, use imagens da URL:
	•	https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png
	5.	Use nomes de classes em português (exemplo: <section class="secaoDestaque">).
	6.	Se um vídeo for necessário, insira um iframe responsivo (16:9) com esta URL:
	•	https://www.youtube.com/embed/F5KmwYu3vco?si=TCSfAX1ThyRJztKR

Regras do CSS
	1.	O fundo da seção deve ser personalizado (neutro, degradê ou imagem de banco gratuito, combinando com o tema).
	2.	O seletor principal section.secaoNome deve ter:
	•	width: 100%
	•	padding: 80px (para espaçamento interno adequado)
	•	display: flex (use flex-direction: column ou row conforme necessário)
	•	Elementos centralizados com justify-content e align-items.
	3.	Use a fonte padrão: 'Figtree' para:
	•	h1, h2, h3, h4, h5, h6, p, button, a, form, input, textarea, label
	4.	Todos os seletores devem começar com section.secaoNome para evitar conflitos globais.
	5.	Use cores vibrantes e modernas para um design de alto impacto.
	6.	Adicione sombras sutis e transições suaves para um efeito visual profissional.
	7.	Se solicitado um botão, crie um botão animado e chamativo.
	8.	Se precisar de um fundo animado, crie um elemento flutuante com animação e use z-index para manter os demais elementos sobre ele.
  9. Use vídeo e imagem apenas se fizer sentido com o que o cliente pediu

Regras do JavaScript
	1.	Encapsule o código dentro de uma função anônima segura (IIFE).
	2.	Sempre referencie section.secaoNome para evitar conflitos globais.
	3.	Apenas adicione interatividade se necessário (exemplo: exibir/ocultar elementos, efeitos ao rolar a página).
	4.	Se state.informacoes solicitar algo dinâmico, implemente corretamente seguindo todas as regras de estruturação.
	5.	O código deve ser limpo e sem dependências externas.

Formato da Resposta (Siga Estritamente Este Padrão)
	1.	O HTML deve vir antes de HTMLCODE.
	2.	O CSS deve vir antes de CSSCODE.
	3.	O JS deve vir antes de JSCODE.
	4.	Não inclua <head>, <body>, <script>, <style>, <link> ou qualquer outro elemento externo.

Exemplo de Resposta Esperada:

HTMLCODE
<section class="secaoNome">
  <!-- Estrutura da seção aqui -->
</section>

CSSCODE
section.secaoNome {
  /* Estilos aqui */
}

JSCODE
(function() {
  /* Código JS aqui */
})();

IMPORTANTE:
	•	Não envie em Markdown. Responda apenas como texto puro.
	•	Não explique nada. Apenas envie o código no formato solicitado.
	•	Siga todas as regras com exatidão.

`
    const respostaIA = await storeGpt.chat({ mensagem })

    const extrairCodigo = (texto, tipo) => {
      const partes = texto.split(new RegExp(`\\b${tipo}\\b`, 'i'))
      if (partes.length > 1) {
        const conteudo = partes[1].split(/(?:HTMLCODE|CSSCODE|JSCODE|$)/i)[0]
        return conteudo.trim()
      }
      return ''
    }

    storeSecaoComIa.html.codigoHtml = extrairCodigo(respostaIA, 'HTMLCODE')
    storeSecaoComIa.html.codigoCss = extrairCodigo(respostaIA, 'CSSCODE')
    storeSecaoComIa.html.codigoJs = extrairCodigo(respostaIA, 'JSCODE')

    console.log(respostaIA)

    criarSecao()
  } catch (error) {
    state.mostrarMensagem = false
    console.error('Erro ao gerar código com IA:', error)
  }
}
</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
}
p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  padding: 15px;
  border-radius: 10px;
  background-color: var(--cor-azul);
  animation: fadeIn 0.3s linear;
}
.chat .enviar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.chat .enviar .foto {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 15px;
  left: 15px;
}

.chat .enviar textarea {
  padding: 15px 45px 15px 45px;
  transition: all 0.3s;
}

.chat.criando .enviar textarea {
  pointer-events: none;
  background-color: var(--cor-cinza-1);
}

.chat.criando .enviar .carregando {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.chat.criando .enviar button {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.chat .enviar .carregando {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-4);
  position: absolute;
  top: 15px;
  right: 15px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.chat .enviar .carregando span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid var(--cor-branco-fixo);
  border-top: 3px solid transparent;
  animation: girando 0.4s linear infinite;
}

.chat .enviar button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  position: absolute;
  top: 15px;
  right: 15px;
  transition: all 0.3s;
}

.chat .enviar button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transform: rotate(-90deg);
  transition: all 0.3s;
}

.chat .enviar button.ativo {
  background-color: var(--cor-azul);
}

.chat .enviar button.ativo svg {
  fill: var(--cor-branco-fixo);
}
</style>
