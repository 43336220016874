import { defineStore } from 'pinia'

export const useStoreNav2Mobile = defineStore('storeNav2Mobile', {
  state: () => ({
    // Fundo
    fundo: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      }
    },

    // Fundo
    fundoTextoUm: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Fundo
    fundoTextoDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Logo
    logo: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/logo-preto.svg',
      maxWidth: 120,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao1: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 1',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao2: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 2',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao3: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 3',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao4: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 4',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao5: {
      id: '',
      hidden: false,
      positionFixed: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 13,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 5
      },
      padding: {
        top: 10,
        right: 20,
        bottom: 10,
        left: 20
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Opção 5',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao6: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 13,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 5
      },
      padding: {
        top: 10,
        right: 20,
        bottom: 10,
        left: 20
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Opção 6',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    }
  })
})
