<template>
  <div class="image" ref="areaEdicao">
    <!-- Opções -->
    <h2>Imagem</h2>
    <div class="opcoes">
      <button class="abrir" @click="abrirSubirImagem()" :class="{ ativo: state.subirImagem }">
        <Svgs nome="arquivo" />
        <p>Subir</p>
        <p>Imagem</p>
      </button>
      <button class="abrir" @click="abrirLinkImagem()" :class="{ ativo: state.subirLink }">
        <Svgs nome="link" />
        <p>Link</p>
        <p>Imagem</p>
      </button>
      <button class="abrir" @click="abrirRemoverImagem()" :class="{ ativo: state.removerImagem }">
        <Svgs nome="excluir-2" />
        <p>Remover</p>
        <p>Imagem</p>
      </button>
      <button class="abrir icones" @click="abrirBancoIcones()" :class="{ ativo: state.bancoIcones }">
        <Svgs nome="icones" />
        <p>Banco de Ícones</p>
      </button>
    </div>

    <!-- Subir imagem -->
    <div class="balao subir" :class="{ mostrar: state.subirImagem }">
      <div class="nome">
        <h3>Subir imagem</h3>
        <button @click="fecharBalaoSubir()">
          <Svgs nome="x" />
        </button>
      </div>
      <div class="conteudo">
        <button class="upload" :class="{ carregar: state.loading }" @click="abrirInputFile">
          <span></span>
          <div class="icone">
            <Svgs nome="arquivo" />
          </div>
          <div class="texto">
            <h3>{{ state.nomeArquivo || 'Subir Imagem' }}</h3>
            <p>{{ state.tamanhoArquivo || 'Clique aqui e suba sua imagem' }}</p>
          </div>
          <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif,.webp" hidden @change="selecionarImagem" ref="inputFile" />
        </button>
      </div>
      <div class="conteudo" v-if="state.linkArquivo !== 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png'">
        <div class="previa">
          <button class="excluir" @click="excluirImagem()">
            <Svgs nome="excluir-2" />
          </button>
          <div class="imagem" :style="`background-image: url('${state.linkArquivo}')`"></div>
        </div>
        <BotaoSalvar nome="Salvar Imagem" @click="salvarImagem()" />
      </div>
    </div>

    <!-- Link imagem -->
    <div class="balao link" :class="{ mostrar: state.subirLink }">
      <div class="nome">
        <h3>Link da Imagem</h3>
        <button @click="fecharBalaoLink()">
          <Svgs nome="x" />
        </button>
      </div>
      <div class="conteudo">
        <textarea v-model="state.linkArquivo" spellcheck="false" autocomplete="off" v-redimensionar-textarea></textarea>
      </div>
      <div class="conteudo">
        <div class="previa">
          <button class="excluir" @click="excluirImagem()">
            <Svgs nome="excluir-2" />
          </button>
          <div class="imagem" :style="`background-image: url('${state.linkArquivo}')`"></div>
        </div>
        <BotaoSalvar nome="Salvar Imagem" @click="salvarLink()" />
      </div>
    </div>

    <!-- Remover imagem -->
    <div class="balao remover" :class="{ mostrar: state.removerImagem }">
      <div class="nome">
        <h3>Remover Imagem</h3>
        <button @click="fecharBalaoRemover()">
          <Svgs nome="x" />
        </button>
      </div>
      <div class="conteudo">
        <div class="previa">
          <div class="imagem" :style="`background-image: url('${state.linkArquivo}')`"></div>
        </div>
        <BotaoExcluir nome="Excluir Imagem" @click="deletarImagem()" />
      </div>
    </div>

    <!-- Banco ícones -->
    <div class="balao icones" :class="{ mostrar: state.bancoIcones }">
      <div class="nome">
        <h3>Banco de Ícones</h3>
        <button @click="fecharBalaoIcones()">
          <Svgs nome="x" />
        </button>
      </div>
      <div class="conteudo">
        <SectionLista class="minimizado" />
        <Teleport to="body">
          <ModalIcone />
          <ModalSugerir />
        </Teleport>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, inject, ref, computed } from 'vue'
import { useStoreAjustes } from '@stores'
import { helperClicouFora } from '@helpers'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import BotaoExcluir from '@components/global/elementos/BotaoExcluir.vue'
import ApiUpload from '@/api/upload/api-upload.js'
import Svgs from '@svgs'
import SectionLista from '@/components/app/ferramentas/icones/partials/SectionLista.vue'
import ModalIcone from '@/components/app/ferramentas/icones/modals/ModalIcone.vue'
import ModalSugerir from '@/components/app/ferramentas/icones/modals/ModalSugerir.vue'

const props = defineProps(['selecao'])
const emitter = inject('emitter')
const storeAjustes = useStoreAjustes()

const areaEdicao = ref(null)
const inputFile = ref(null)

const state = reactive({
  loading: false,
  subirImagem: false,
  subirLink: false,
  removerImagem: false,
  bancoIcones: false,
  linkArquivo: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  termoBuscaBanco: '',
  imagemSelecionadaBanco: '',
  imagensBanco: []
})

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 1920
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.linkArquivo = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso

      arquivo.target.value = ''
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem' })

      arquivo.target.value = ''
    })
}

function abrirBancoIcones() {
  state.bancoIcones = true
  state.subirImagem = false
  state.subirLink = false
  state.removerImagem = false
}

function abrirSubirImagem() {
  state.subirImagem = true
  state.subirLink = false
  state.removerImagem = false
  state.bancoIcones = false
  renderizarImagem()
}

function abrirLinkImagem() {
  state.subirLink = true
  state.subirImagem = false
  state.removerImagem = false
  state.bancoIcones = false
  renderizarImagem()
}

function abrirRemoverImagem() {
  state.removerImagem = true
  state.subirImagem = false
  state.subirLink = false
  state.bancoIcones = false
  renderizarImagem()
}

function fecharBalaoSubir() {
  state.subirImagem = false
  state.linkArquivo = ''
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
}

function fecharBalaoIcones() {
  state.bancoIcones = false
}

function fecharBalaoLink() {
  state.subirLink = false
  state.linkArquivo = ''
}

function fecharBalaoRemover() {
  state.removerImagem = false
  state.linkArquivo = ''
}

function fecharTudo() {
  state.subirLink = false
  state.subirImagem = false
  state.removerImagem = false
  state.bancoIcones = false
}

function salvarImagem() {
  elementoSelecionado.value.image = state.linkArquivo
  fecharBalaoSubir()
}

function salvarLink() {
  elementoSelecionado.value.image = state.linkArquivo
  fecharBalaoLink()
}

function deletarImagem() {
  elementoSelecionado.value.image = 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png'
  state.removerImagem = false
}

function excluirImagem() {
  state.linkArquivo = 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png'
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
}

function renderizarImagem() {
  state.linkArquivo = elementoSelecionado.value.image
}

function aplicarIcone(url) {
  elementoSelecionado.value.image = url
  fecharTudo()
}

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

helperClicouFora.clicouFora([
  {
    alvo: areaEdicao,
    retornoChamada: () => fecharTudo()
  }
])

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    fecharBalaoSubir()
    fecharBalaoLink()
    fecharBalaoRemover()
  }
})

onMounted(() => {
  emitter.on('aplicarIcone', (url) => {
    aplicarIcone(url)
  })
})
</script>

<style scoped>
.image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid var(--cor-cinza-3);
  animation: fadeIn 0.3s ease-in-out;
}

h2 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  margin: 0 0 10px 0;
}

.opcoes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.botao {
  width: 100%;
}

button.abrir {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  width: calc(33.33% - 5px);
  padding: 15px 0;
  transition: all 0.3s;
}

button.abrir:disabled {
  pointer-events: none;
  opacity: 0.5;
}

button.abrir.icones {
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 15px;
}

button.abrir.ativo {
  background-color: var(--cor-azul);
}

button.abrir.ativo p {
  color: var(--cor-branco-fixo);
}

button.abrir.ativo svg {
  fill: var(--cor-branco-fixo);
}

button.abrir:hover p {
  color: var(--cor-branco);
}

button.abrir.icones svg {
  width: 16px;
  max-width: 16px;
  margin: 0 10px 0 0;
}

button.abrir svg {
  width: 15px;
  max-width: 15px;
  fill: var(--cor-branco);
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

button.abrir.pexels p {
  margin: 0;
}

button.abrir.icones p {
  margin: 0;
}

button.abrir p {
  font-family: var(--regular);
  font-size: var(--f0);
  color: var(--cor-branco);
  margin: 3px 0 0 0;
  transition: all 0.3s;
}

.topo {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: var(--cor-cinza-2);
  z-index: 2;
}

.balao.banco .nome {
  padding: 0;
}

.nome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.nome h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.nome button {
  background-color: transparent;
}

.nome button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.nome button:hover svg {
  fill: var(--cor-vermelho);
}

.balao {
  display: none;
  flex-direction: column;
  align-items: center;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  overflow-y: scroll;
  position: fixed;
  top: 100px;
  right: 270px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  transition: all 0.3s;
}

.balao.banco {
  width: 450px;
  min-width: 450px;
  max-width: 450px;
}

.balao.banco .conteudo {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 50dvh;
  max-height: 50dvh;
  min-height: 50dvh;
  padding: 90px 20px 100px 20px;
}

.balao.icones .conteudo {
  padding: 0;
}

.balao.mostrar {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}

.balao .conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--cor-cinza-4);
  background-color: transparent;
  border-radius: 10px;
  position: relative;
  width: 100%;
  padding: 30px;
  transition: all 0.3s;
}
.upload:hover {
  border: 1px dashed var(--cor-azul);
}
.upload span {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid var(--cor-branco);
  border-top: 3px solid transparent;
  animation: girando 1s linear infinite;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}
.upload.carregar {
  pointer-events: none;
}
.upload.carregar span {
  opacity: 1;
  visibility: visible;
}
.upload.carregar .icone {
  opacity: 0;
  visibility: hidden;
}
.upload.carregar .texto {
  opacity: 0;
  visibility: hidden;
}
.upload .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 30px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}
.upload .icone svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  position: absolute;
}
.upload .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0 0;
  transition: all 0.3s;
}
.upload .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  word-break: break-all;
  line-height: 1.3;
}
.upload .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.previa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  background-size: cover;
  background-position: center;
  background-image: url('https://arquivos.unicpages.com/imagens/app/fundo-png.svg');
  position: relative;
  margin: 0 0 20px 0;
}

.previa button.excluir {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  z-index: 2;
  transition: all 0.3s;
}

.previa button.excluir:hover {
  background-color: var(--cor-vermelho);
}

.previa button.excluir:hover svg {
  fill: var(--cor-branco-fixo);
}

.previa button.excluir svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.previa .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  z-index: 1;
}

.balao textarea {
  background-color: var(--cor-cinza-3);
}

.balao textarea:focus {
  background-color: var(--cor-cinza-4);
}

.input {
  position: relative;
  margin: 10px 0 0 0;
}

.input input {
  background-color: var(--cor-cinza-3);
}

.input input:focus {
  background-color: var(--cor-cinza-4);
}

.input svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  position: absolute;
  left: 15px;
}

.input button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
  height: 100%;
  padding: 0 20px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 10px 10px 0;
  transition: all 0.3s;
}

.input button:hover {
  background-color: var(--cor-azul);
}

.galeria {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0 0 0;
}

.galeria .item {
  width: calc(50% - 5px);
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

.galeria .item:hover {
  opacity: 0.7;
}

.galeria .item.ativo {
  pointer-events: none;
}

.galeria .item.ativo .tamanho .imagem {
  border: 2px solid var(--cor-azul);
}

.galeria .item .tamanho {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.galeria .item .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--cor-cinza-3);
  border: 2px solid var(--cor-cinza-3);
  border-radius: 5px;
  transition: all 0.3s;
}

.botao {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: var(--cor-cinza-2);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .subir {
    top: 120px;
    right: 20px;
    width: 100%;
    max-width: 250px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }

  .link {
    top: 120px;
    right: 20px;
    width: 100%;
    max-width: 250px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }

  .remover {
    top: 120px;
    right: 20px;
    width: 100%;
    max-width: 250px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }

  .balao.banco {
    width: 330px;
    min-width: 330px;
    max-width: 330px;
    right: 20px;
    top: 120px;
  }

  .balao.banco .conteudo {
    height: 40dvh;
    max-height: 40dvh;
    min-height: 40dvh;
    padding: 90px 20px 100px 20px;
  }

  .subir button.enviar {
    padding: 20px 20px 10px 20px;
  }
}
</style>
