import { defineStore } from 'pinia'

export const useStoreSecaoComIa = defineStore('storeSecaoComIa', {
  state: () => ({
    html: {
      hidden: false,
      codigoHtml: `

`,
      codigoCss: `

`,
      codigoJs: `

`
    }
  })
})
