<template>
  <!-- Camadas -->
  <div class="camadas">
    <div class="titulo">
      <h3>Camadas</h3>
      <div class="toggle" @click="alternarDispositivo" :class="state.dispositivo">
        <p>Desktop</p>
        <p>Mobile</p>
        <span></span>
      </div>
    </div>

    <!-- Desktop -->
    <div class="elementos" :class="{ mostrar: state.dispositivo === 'desktop' }" v-for="(secao, index) in state.camadasDesktop" :key="`secao-${index}`">
      <div class="topo" :class="{ ativo: secao.mostrarItens }" @click="toggleMostrarItensDesktop(index)">
        <h4>
          <span>{{ index + 1 }}ª</span>
          {{ formatarTitulo(secao?.modelo) }}
        </h4>
        <button class="excluir" @click="excluirSecaoDesktop(secao.id)">
          <Svgs nome="excluir" />
        </button>
        <Svgs nome="setinha" class="setinha" />
      </div>
      <div class="itens" :class="{ mostrar: secao.mostrarItens }">
        <div class="elemento" v-for="(elemento, index) in secao.elementos" :class="{ oculto: elemento.oculto, ativo: storeCriar.elementoMarcado?.startsWith(elemento.nome) && storeCriar.elementoMarcado?.endsWith('desktop') }" :key="`elemento-${index}`" @click="selecionarItem(secao, elemento, 'desktop')">
          <span></span>
          <p>{{ formatarNome(elemento.nome) }}</p>
          <button @click="mudarHidden(secao.id, elemento.nome)">
            <Svgs :nome="`${elemento.oculto === true ? 'oculto' : 'visivel'}`" />
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div class="elementos" :class="{ mostrar: state.dispositivo === 'mobile' }" v-for="(secao, index) in state.camadasMobile" :key="`secao-${index}`">
      <div class="topo" @click="toggleMostrarItensMobile(index)">
        <h4>
          <span>{{ index + 1 }}ª</span>
          {{ formatarTitulo(secao?.modelo) }}
        </h4>
        <button class="excluir" @click="excluirSecaoMobile(secao.id)">
          <Svgs nome="excluir" />
        </button>
        <Svgs nome="setinha" class="setinha" />
      </div>
      <div class="itens" :class="{ mostrar: secao.mostrarItens }">
        <div class="elemento" v-for="(elemento, index) in secao.elementos" :class="{ oculto: elemento.oculto, ativo: storeCriar.elementoMarcado?.startsWith(elemento.nome) && storeCriar.elementoMarcado?.endsWith('mobile') }" :key="`elemento-${index}`" @click="selecionarItem(secao, elemento, 'mobile')">
          <span></span>
          <p>{{ formatarNome(elemento.nome) }}</p>
          <button @click="mudarHidden(secao.id, elemento.nome)">
            <Svgs :nome="`${elemento.oculto === true ? 'oculto' : 'visivel'}`" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, inject, computed, watch } from 'vue'
import { useStoreCriar, useStoreAjustes, useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()
const storeModal = useStoreModal()

const emitter = inject('emitter')
const idElemento = computed(() => state.elementoSelecionado + state.indiceSecao + state.dispositivo)

const state = reactive({
  mostrarEdicoesMobile: false,
  debounceOnline: null,
  camadasDesktop: [],
  camadasMobile: [],
  mostrarItens: false,
  siteCompartilhado: true,
  elementoSelecionado: '',
  indiceSecao: 0,
  dispositivo: 'desktop',
  idElementoMarcado: ''
})

function selecionarItem(secao, elemento, dispositivo) {
  if (dispositivo === 'desktop') {
    state.indiceSecao = state.camadasDesktop.findIndex((camada) => camada.id === secao.id)
  } else {
    state.indiceSecao = state.camadasMobile.findIndex((camada) => camada.id === secao.id)
  }

  state.elementoSelecionado = elemento.nome

  storeCriar.elementoMarcado = idElemento.value

  const evento = {
    dispositivo: dispositivo,
    indiceSecao: state.indiceSecao,
    elementoSelecionado: elemento.nome
  }

  if (/imagem|icone/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesImagem', { evento })
  } else if (/^fundo$/i.test(state.elementoSelecionado)) {
    emitter.emit('mostrarAjustesSecao', { evento })
  } else if (/fundo.*quadro|quadro.*fundo/i.test(state.elementoSelecionado)) {
    emitter.emit('mostrarAjustesQuadro', { evento })
  } else if (/fundo/i.test(state.elementoSelecionado)) {
    emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
  } else if (/botao/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesBotao', { evento })
  } else if (/video/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesVideo', { evento })
  } else if (/divisor/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesDivisor', { evento })
  } else if (/input/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesInput', { evento })
  } else if (/textarea/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesTextarea', { evento })
  } else if (/select/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesSelect', { evento })
  } else if (/html/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesHtml', { evento })
  } else if (/titulo.*icone|icone.*titulo/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesImagem', { evento })
  } else if (/titulo.*botao|botao.*titulo/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesBotao', { evento })
  } else if (/titulo|texto|label/i.test(elemento.nome)) {
    emitter.emit('mostrarAjustesTexto', { evento })
  }
}

function toggleMostrarItensDesktop(index) {
  const camada = state.camadasDesktop[index]
  camada.mostrarItens = !camada.mostrarItens
}

function toggleMostrarItensMobile(index) {
  const camada = state.camadasMobile[index]
  camada.mostrarItens = !camada.mostrarItens
}

function alternarDispositivo() {
  state.dispositivo = state.dispositivo === 'desktop' ? 'mobile' : 'desktop'
}

function exibirCamadas(dispositivo) {
  const secoes = storeAjustes.secoes[dispositivo] || '[]'
  return secoes?.map((secao) => {
    const modelo = secao?.modelo?.replace('Desktop', '')?.replace('Mobile', '')

    const elementos = Object.keys(secao.propriedades).map((elemento) => {
      const oculto = secao.propriedades[elemento]?.hidden || false
      return { nome: elemento, oculto }
    })

    return { id: secao.id, modelo, elementos }
  })
}

function carregarCamadas() {
  setTimeout(() => {
    state.camadasDesktop = exibirCamadas('desktop')
    state.camadasMobile = exibirCamadas('mobile')
  }, 1200)
}

function formatarNome(nome) {
  return nome
    .replace(/([A-Z])/g, ' $1')
    .replace(/(\d+)/g, ' $1')
    .trim()
}

function formatarTitulo(nome) {
  return nome.replace(/([1-9])/g, ' $1').trim()
}

function mudarHidden(id, nomeElemento) {
  const dispositivo = state.dispositivo
  const secoes = storeAjustes?.secoes[dispositivo]

  // store
  const indiceSecao = secoes.findIndex((secao) => secao.id === id)
  if (indiceSecao < 0) return
  const secaoPropriedade = secoes[indiceSecao].propriedades[nomeElemento]
  secaoPropriedade.hidden = !secaoPropriedade.hidden

  // olhinho
  const camada = dispositivo === 'desktop' ? state.camadasDesktop : state.camadasMobile
  const indiceElemento = camada[indiceSecao].elementos.findIndex((elemento) => elemento.nome === nomeElemento)
  if (indiceElemento < 0) return

  const camadaElemento = camada[indiceSecao].elementos[indiceElemento]
  camadaElemento.oculto = !camadaElemento.oculto
}

function excluirSecaoDesktop(id) {
  storeModal.abrirModalEdicao('excluirSecaoDesktop', 'desktop', 'Excluir seção Desktop', id)
}

function excluirSecaoMobile(id) {
  storeModal.abrirModalEdicao('excluirSecaoMobile', 'mobile', 'Excluir seção Mobile', id)
}

watch(
  () => storeAjustes.secoes,
  () => carregarCamadas(),
  { deep: true, immediate: true }
)

onMounted(() => {
  carregarCamadas()
  emitter.on('atualizarCamadasSite', carregarCamadas)
})
</script>

<style scoped>
.camadas {
  display: flex;
  flex-direction: column;
  padding: 20px 0 100px 0;
  width: 100%;
  border-top: 2px solid var(--cor-cinza-3);
}

.camadas .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 0 20px;
}

.camadas .titulo .toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 25px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}

.camadas .titulo .toggle:hover {
  background-color: var(--cor-cinza-4);
}

.camadas .titulo .toggle span {
  width: 50%;
  height: 100%;
  background-color: var(--cor-cinza-5);
  border-radius: 50px;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.camadas .titulo .toggle.mobile span {
  left: 50%;
}

.camadas .titulo .toggle p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco-fixo);
  width: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.camadas .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.camadas .topo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 15px 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.camadas .topo:hover h4 {
  color: var(--cor-branco);
}

.camadas .topo .excluir {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  right: 40px;
}

.camadas .topo .excluir:hover svg {
  fill: var(--cor-vermelho);
}

.camadas .topo .excluir svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

.camadas .topo svg.setinha {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-cinza-5);
  position: absolute;
  right: 20px;
  top: 15px;
}

.camadas .topo.ativo h4 span {
  color: var(--cor-branco);
}

.camadas .topo h4 span {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
  padding: 2px 0;
  text-align: center;
  min-width: 25px;
  width: 25px;
  margin: 0 5px 0 0;
  border-radius: 5px;
}

.camadas .topo h4 {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.camadas .elementos {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.camadas .elementos.mostrar {
  display: flex;
}

.camadas .elementos .itens {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.camadas .elementos .itens.mostrar {
  display: flex;
  opacity: 1;
  visibility: visible;
  animation: mostrarItens 0.3s linear;
}

@keyframes mostrarItens {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.camadas .elemento {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.camadas .elemento.ativo {
  background-color: var(--cor-cinza-3);
}

.camadas .elemento.ativo p {
  color: var(--cor-branco) !important;
}

.camadas .elemento.ativo svg {
  fill: var(--cor-branco);
}

.camadas .elemento:hover {
  background-color: var(--cor-cinza-3);
}

.camadas .elemento.oculto p {
  color: var(--cor-vermelho);
}

.camadas .elemento.oculto svg {
  fill: var(--cor-vermelho);
}

.camadas .elemento p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  text-transform: lowercase;
  overflow: hidden;
  transition: all 0.3s;
}

.camadas .elemento button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  margin-left: auto;
  transition: all 0.3s;
}

.camadas .elemento button:hover {
  background-color: var(--cor-cinza-3);
}

.camadas .elemento button:hover svg {
  fill: var(--cor-branco);
}

.camadas .elemento button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .camadas {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    width: 100%;
    border-top: 2px solid var(--cor-cinza-3);
  }
}
</style>
