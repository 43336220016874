import { defineStore } from 'pinia'

export const useStoreFooter1Desktop = defineStore('storeFooter1Desktop', {
  state: () => ({
    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 60,
        right: 80,
        bottom: 80,
        left: 80
      }
    },

    // Fundo
    fundoTextoUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Fundo
    fundoTextoDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Fundo
    fundoTextoTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Fundo
    fundoTextoQuatro: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Logo
    logo: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/logo-preto.svg',
      maxWidth: 150,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Titulo
    titulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo'
    },

    // Parágrafo
    subtitulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Titulo
    tituloDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Título'
    },

    // Titulo
    tituloTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Título'
    },

    // Titulo
    tituloQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Título'
    },

    // Botão
    botaoOpcao1: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 1',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao2: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 2',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao3: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 3',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao4: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 4',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao5: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 5',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao6: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 6',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao7: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 7',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao8: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 8',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao9: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 9',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao10: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 10',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao11: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 11',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    // Botão
    botaoOpcao12: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 12',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    }
  })
})
