<template>
  <section class="ajustes" @mouseenter="adicionarClasseBloqueado" @mouseleave="removerClasseBloqueado">
    <div class="edicoes" :class="{ aparecer: state.mostrarAjustesMobile }">
      <div class="vazio" v-if="isTudoFalso">
        <Svgs nome="logo-flat" />
        <p>Selecione algum elemento do site para mostrar as opções de edição nessa lateral</p>
      </div>
      <DivHidden v-if="state.mostrar.hidden === true" :selecao="state.evento" />
      <DivAnimationDuration v-if="state.mostrar.animationDuration === true" :selecao="state.evento" />
      <DivAnimationName v-if="state.mostrar.animationName === true" :selecao="state.evento" />
      <DivText v-if="state.mostrar.text === true" :selecao="state.evento" />
      <DivSelect v-if="state.mostrar.select === true" :selecao="state.evento" />
      <DivGoogleMaps v-if="state.mostrar.googleMaps === true" :selecao="state.evento" />
      <DivDiscord v-if="state.mostrar.webhookDiscord === true" :selecao="state.evento" />
      <DivActiveCampaign v-if="state.mostrar.activeCampaign === true" :selecao="state.evento" />
      <DivSysteme v-if="state.mostrar.systeme === true" :selecao="state.evento" />
      <DivMailchimp v-if="state.mostrar.mailchimp === true" :selecao="state.evento" />
      <DivUrl v-if="state.mostrar.url === true" :selecao="state.evento" />
      <DivMaxWidth v-if="state.mostrar.maxWidth === true" :selecao="state.evento" />
      <DivMaxHeight v-if="state.mostrar.maxHeight === true" :selecao="state.evento" />
      <DivImage v-if="state.mostrar.image === true" :selecao="state.evento" />
      <DivLink v-if="state.mostrar.link === true" :selecao="state.evento" />
      <DivLinkTarget v-if="state.mostrar.linkTarget === true" :selecao="state.evento" />
      <DivFontFamily v-if="state.mostrar.fontFamily === true" :selecao="state.evento" />
      <DivFontSize v-if="state.mostrar.fontSize === true" :selecao="state.evento" />
      <DivLineHeight v-if="state.mostrar.lineHeight === true" :selecao="state.evento" />
      <DivTextAlign v-if="state.mostrar.textAlign === true" :selecao="state.evento" />
      <DivLetterSpacing v-if="state.mostrar.letterSpacing === true" :selecao="state.evento" />
      <DivTextDecoration v-if="state.mostrar.textDecoration === true" :selecao="state.evento" />
      <DivColor v-if="state.mostrar.color === true" :selecao="state.evento" />
      <DivSpanColor v-if="state.mostrar.spanColor === true" :selecao="state.evento" />
      <DivBackgroundColor v-if="state.mostrar.backgroundColor === true" :selecao="state.evento" />
      <DivGradient v-if="state.mostrar.gradient === true" :selecao="state.evento" />
      <DivDirection v-if="state.mostrar.direction === true" :selecao="state.evento" />
      <DivAlignItems v-if="state.mostrar.alignItems === true" :selecao="state.evento" />
      <DivJustifyContent v-if="state.mostrar.justifyContent === true" :selecao="state.evento" />
      <DivBorderRadius v-if="state.mostrar.borderRadius === true" :selecao="state.evento" />
      <DivMargin v-if="state.mostrar.margin === true" :selecao="state.evento" />
      <DivPadding v-if="state.mostrar.padding === true" :selecao="state.evento" />
      <DivHtml v-if="state.mostrar.html === true" :selecao="state.evento" />
      <DivPositionFixed v-if="state.mostrar.positionFixed === true" :selecao="state.evento" />
      <DivId v-if="state.mostrar.id === true" :selecao="state.evento" />
    </div>
    <button class="mostrar" :class="{ ativo: state.mostrarAjustesMobile }" @click="state.mostrarAjustesMobile = !state.mostrarAjustesMobile">
      <Svgs nome="setinha" />
    </button>
  </section>
</template>

<script setup>
import { reactive, onMounted, inject, computed, onUnmounted, watch } from 'vue'
import { useStoreCriar, useStoreAjustes } from '@stores'
import Svgs from '@svgs'

import DivFontSize from '@components/criar/ajustes/DivFontSize.vue'
import DivMargin from '@components/criar/ajustes/DivMargin.vue'
import DivPadding from '@components/criar/ajustes/DivPadding.vue'
import DivColor from '@components/criar/ajustes/DivColor.vue'
import DivBackgroundColor from '@components/criar/ajustes/DivBackgroundColor.vue'
import DivText from '@components/criar/ajustes/DivText.vue'
import DivMaxWidth from '@components/criar/ajustes/DivMaxWidth.vue'
import DivMaxHeight from '@components/criar/ajustes/DivMaxHeight.vue'
import DivLineHeight from '@components/criar/ajustes/DivLineHeight.vue'
import DivFontFamily from '@components/criar/ajustes/DivFontFamily.vue'
import DivImage from '@components/criar/ajustes/DivImage.vue'
import DivTextAlign from '@components/criar/ajustes/DivTextAlign.vue'
import DivBorderRadius from '@components/criar/ajustes/DivBorderRadius.vue'
import DivLink from '@components/criar/ajustes/DivLink.vue'
import DivHidden from '@components/criar/ajustes/DivHidden.vue'
import DivDirection from '@components/criar/ajustes/DivDirection.vue'
import DivAlignItems from '@components/criar/ajustes/DivAlignItems.vue'
import DivJustifyContent from '@components/criar/ajustes/DivJustifyContent.vue'
import DivUrl from '@components/criar/ajustes/DivUrl.vue'
import DivLinkTarget from '@components/criar/ajustes/DivLinkTarget.vue'
import DivAnimationDuration from '@components/criar/ajustes/DivAnimationDuration.vue'
import DivGradient from '@components/criar/ajustes/DivGradient.vue'
import DivSpanColor from '@components/criar/ajustes/DivSpanColor.vue'
import DivActiveCampaign from '@components/criar/ajustes/DivActiveCampaign.vue'
import DivSysteme from '@components/criar/ajustes/DivSysteme.vue'
import DivMailchimp from '@components/criar/ajustes/DivMailchimp.vue'
import DivGoogleMaps from '@components/criar/ajustes/DivGoogleMaps.vue'
import DivDiscord from '@components/criar/ajustes/DivDiscord.vue'
import DivSelect from '@components/criar/ajustes/DivSelect.vue'
import DivPositionFixed from '@components/criar/ajustes/DivFixed.vue'
import DivLetterSpacing from '@components/criar/ajustes/DivLetterSpacing.vue'
import DivTextDecoration from '@components/criar/ajustes/DivTextDecoration.vue'
import DivAnimationName from '@components/criar/ajustes/DivAnimationName.vue'
import DivId from '@components/criar/ajustes/DivId.vue'
import DivHtml from '@components/criar/ajustes/DivHtml.vue'

const storeAjustes = useStoreAjustes()
const storeCriar = useStoreCriar()
const emitter = inject('emitter')
const state = reactive({
  mostrarAjustesMobile: false,
  evento: {
    dispositivo: null,
    indiceSecao: null,
    elementoSelecionado: null
  },
  mostrar: {
    hidden: false,
    direction: false,
    alignItems: false,
    justifyContent: false,
    textAlign: false,
    borderRadius: false,
    color: false,
    backgroundColor: false,
    fontFamily: false,
    image: false,
    lineHeight: false,
    url: false,
    link: false,
    margin: false,
    padding: false,
    text: false,
    fontSize: false,
    maxWidth: false,
    maxHeight: false,
    linkTarget: false,
    animationDuration: false,
    gradient: false,
    spanColor: false,
    activeCampaign: false,
    systeme: false,
    mailchimp: false,
    googleMaps: false,
    webhookDiscord: false,
    select: false,
    positionFixed: false,
    letterSpacing: false,
    textDecoration: false,
    animationName: false,
    id: false,
    html: false
  }
})

const opcoes = ['hidden', 'direction', 'alignItems', 'justifyContent', 'textAlign', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'image', 'lineHeight', 'url', 'link', 'margin', 'padding', 'text', 'fontSize', 'maxWidth', 'maxHeight', 'linkTarget', 'animationDuration', 'gradient', 'spanColor', 'activeCampaign', 'systeme', 'mailchimp', 'googleMaps', 'webhookDiscord', 'select', 'positionFixed', 'letterSpacing', 'textDecoration', 'animationName', 'id', 'html']

function definirAjustesMostrados(opcoesAtivas) {
  opcoes.forEach((opcao) => {
    state.mostrar[opcao] = opcoesAtivas.includes(opcao)
  })
}

onMounted(() => {
  const mostrarAjustes = (evento, opcoesAtivas) => {
    state.evento = evento.evento
    state.mostrarAjustesMobile = true
    definirAjustesMostrados(opcoesAtivas)
  }

  const ocultarAjustes = () => {
    state.mostrarAjustesMobile = false
    definirAjustesMostrados([])
  }

  // Html
  emitter.on('mostrarAjustesHtml', (evento) => mostrarAjustes(evento, ['hidden', 'positionFixed', 'html'])),
    // Imagem
    emitter.on('mostrarAjustesImagem', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'image', 'link', 'margin', 'maxWidth', 'linkTarget', 'id']))
  // Imagem animada
  emitter.on('mostrarAjustesImagemAnimada', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'image', 'margin', 'maxWidth', 'animationDuration', 'animationName', 'id']))
  // Texto
  emitter.on('mostrarAjustesTexto', (evento) => mostrarAjustes(evento, ['hidden', 'textAlign', 'color', 'fontFamily', 'lineHeight', 'margin', 'text', 'fontSize', 'maxWidth', 'spanColor', 'letterSpacing', 'textDecoration', 'id']))
  // Background
  emitter.on('mostrarAjustesBackground', (evento) => mostrarAjustes(evento, ['hidden', 'backgroundColor', 'image', 'padding', 'gradient', 'id']))
  // Botão
  emitter.on('mostrarAjustesBotao', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'link', 'margin', 'padding', 'text', 'fontSize', 'linkTarget', 'gradient', 'spanColor', 'letterSpacing', 'textDecoration', 'id']))
  // Divisor
  emitter.on('mostrarAjustesDivisor', (evento) => mostrarAjustes(evento, ['hidden', 'image', 'id']))
  // Seção
  emitter.on('mostrarAjustesSecao', (evento) => mostrarAjustes(evento, ['hidden', 'direction', 'alignItems', 'justifyContent', 'backgroundColor', 'image', 'padding', 'gradient', 'positionFixed', 'id']))
  // Seção sem fundo
  emitter.on('mostrarAjustesSecaoSemFundo', (evento) => mostrarAjustes(evento, ['hidden', 'direction', 'alignItems', 'justifyContent', 'padding', 'id']))
  // Quadro
  emitter.on('mostrarAjustesQuadro', (evento) => mostrarAjustes(evento, ['hidden', 'direction', 'alignItems', 'justifyContent', 'borderRadius', 'backgroundColor', 'image', 'margin', 'padding', 'maxWidth', 'gradient', 'id']))
  // Vídeo
  emitter.on('mostrarAjustesVideo', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'url', 'margin', 'maxWidth', 'maxHeight', 'id']))
  // Input
  emitter.on('mostrarAjustesInput', (evento) => mostrarAjustes(evento, ['hidden', 'textAlign', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'margin', 'padding', 'text', 'fontSize', 'maxWidth', 'letterSpacing', 'textDecoration', 'id']))
  // Textarea
  emitter.on('mostrarAjustesTextarea', (evento) => mostrarAjustes(evento, ['hidden', 'textAlign', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'lineHeight', 'margin', 'padding', 'text', 'fontSize', 'maxWidth', 'maxHeight', 'letterSpacing', 'textDecoration', 'id']))
  // Select
  emitter.on('mostrarAjustesSelect', (evento) => mostrarAjustes(evento, ['hidden', 'textAlign', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'margin', 'padding', 'text', 'fontSize', 'maxWidth', 'select', 'id']))
  // Active Campaign
  emitter.on('mostrarAjustesActiveCampaign', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'margin', 'padding', 'text', 'fontSize', 'linkTarget', 'gradient', 'spanColor', 'activeCampaign', 'id']))
  // Active Campaign
  emitter.on('mostrarAjustesSysteme', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'margin', 'padding', 'text', 'fontSize', 'linkTarget', 'gradient', 'spanColor', 'systeme', 'id']))
  // Mailchimp
  emitter.on('mostrarAjustesMailchimp', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'margin', 'padding', 'text', 'fontSize', 'linkTarget', 'gradient', 'spanColor', 'mailchimp', 'id']))
  // Botão Default
  emitter.on('mostrarAjustesBotaoDefault', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'margin', 'padding', 'text', 'fontSize', 'gradient', 'spanColor', 'id']))
  // Google Maps
  emitter.on('mostrarAjustesGoogleMaps', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'maxWidth', 'maxHeight', 'googleMaps', 'id']))
  // Webhook discord
  emitter.on('mostrarAjustesWebhookDiscord', (evento) => mostrarAjustes(evento, ['hidden', 'borderRadius', 'color', 'backgroundColor', 'fontFamily', 'margin', 'padding', 'text', 'fontSize', 'linkTarget', 'gradient', 'spanColor', 'webhookDiscord', 'id']))
  // Animação
  emitter.on('mostrarAjustesAnimacao', (evento) => mostrarAjustes(evento, ['alignItems', 'animationDuration', 'id']))
  // Ocultar tudo
  emitter.on('ocultarAjustes', ocultarAjustes)

  document.addEventListener('keydown', ativarCopiarColar)
})

const isTudoFalso = computed(() => {
  for (const propriedade in state.mostrar) {
    if (state.mostrar[propriedade]) return false
  }
  return true
})

function adicionarClasseBloqueado() {
  document.body.classList.add('bloqueado')
  storeCriar.liberadoCopiarElemento = false
}

function removerClasseBloqueado(event) {
  if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
    document.body.classList.remove('bloqueado')
    storeCriar.liberadoCopiarElemento = true
  }
}

function ativarCopiarColar(event) {
  if (storeCriar.bodyBloqueado) return
  if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
    const elementoCopiado = storeCriar.elementoMarcado
    const propriedades = storeAjustes.pegarPropriedadesElemento(elementoCopiado)

    const propriedadesJson = JSON.stringify(propriedades)
    navigator.clipboard.writeText(propriedadesJson).then(() => {})
  }

  if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
    navigator.clipboard.readText().then((conteudo) => {
      const objetoColado = JSON.parse(conteudo)

      const elementoCopiado = storeCriar.elementoMarcado
      storeAjustes.colarPropriedades(objetoColado, elementoCopiado)
    })
  }
}

onUnmounted(() => {
  document.body.classList.remove('bloqueado')
  document.removeEventListener('keydown', ativarCopiarColar)
})

watch(
  () => state.mostrarAjustesMobile,
  (novoValor) => {
    if (novoValor) {
      document.body.classList.add('bloqueado')
    } else {
      document.body.classList.remove('bloqueado')
    }
  }
)
</script>

<style scoped>
section.ajustes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-cinza-2);
  z-index: 10;
}

.edicoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
  padding: 0 0 30px 0;
  transition: all 0.5s;
}

.edicoes::-webkit-scrollbar-track {
  background-color: transparent;
}

.vazio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100dvh;
  animation: fadeIn 0.3s ease-in-out;
}

.vazio svg {
  width: 100px;
  min-width: 100px;
  fill: var(--cor-cinza-5);
  margin: 0 0 10px 0;
}

.vazio p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 200px;
}

button.mostrar {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  width: 100%;
  height: 30px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px 10px 0 0;
  transition: all 0.3s;
  z-index: 2;
}

button.mostrar:hover {
  background-color: var(--cor-cinza-3);
}

button.mostrar svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
  transform: rotate(180deg);
}

button.mostrar.ativo svg {
  transform: rotate(0deg);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.ajustes {
    top: auto;
    bottom: -20px;
    right: 0;
    min-height: auto;
    max-height: auto;
    height: auto;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .edicoes {
    height: 0dvh;
    padding: 20px 0 30px 0;
  }

  .edicoes.aparecer {
    height: 42dvh;
  }

  button.mostrar {
    display: flex;
  }
}
</style>
