<template>
  <section class="edicao" ref="sectionEdicao" :class="{ aparecerMobile: state.mostrarEdicoesMobile }" @mouseenter="adicionarClasseBloqueado" @mouseleave="removerClasseBloqueado">
    <!-- Topo -->
    <div class="topo">
      <button class="voltar" @click="voltarAoInicio()">
        <Svgs nome="seta" />
        <p>Voltar ao início</p>
      </button>
      <button class="status" :class="{ ativo: page?.online }" @click="mudarVisibilidadeSite">
        <span></span>
        <h3 class="um" v-if="page?.online === true">Online</h3>
        <h3 class="dois" v-if="page?.online === false">Offline</h3>
      </button>
    </div>
    <!-- Nome -->
    <div class="nome">
      <h3>{{ page?.nome || 'Nome do Site' }}</h3>
      <a v-if="page?.dominioPersonalizado" class="dominio" @click="irParaDominio(page)">{{ page?.dominioPersonalizado }}{{ page?.dominioRota ? '/' + page?.dominioRota : '' }}</a>
      <a @click="irParaHotlink(page)">
        {{ 'unic.page/' + page?.hotlink }}
      </a>
      <button class="menu" :class="{ ativo: state.mostrarEdicoesMobile }" @click="state.mostrarEdicoesMobile = !state.mostrarEdicoesMobile">
        <span class="um"></span>
        <span class="dois"></span>
      </button>
    </div>
    <!-- Edições -->
    <div class="edicoes" :class="{ aparecer: state.mostrarEdicoesMobile }">
      <div class="topo">
        <button class="voltar" @click="voltarAoInicio()">
          <Svgs nome="seta" />
          <p>Voltar ao início</p>
        </button>
        <button class="status" :class="{ ativo: page?.online }" @click="mudarVisibilidadeSite">
          <span></span>
          <h3 class="um" v-if="page?.online === true">Online</h3>
          <h3 class="dois" v-if="page?.online === false">Offline</h3>
        </button>
      </div>
      <div class="subir mobile">
        <button @click="abrirModalSubir()">
          <Svgs nome="subir" />
          <p>Subir para o ar</p>
        </button>
      </div>
      <div class="opcoes">
        <button @click="abrirModalInformacoes()">
          <div class="icone">
            <Svgs nome="editar" />
          </div>
          <p>Editar Informações</p>
        </button>
        <button @click="abrirModalFavicon()">
          <div class="icone">
            <Svgs nome="favicon" />
          </div>
          <p>Personalizar Favicon</p>
        </button>
        <button @click="abrirModalDominio()">
          <div class="icone">
            <Svgs nome="dominio" />
          </div>
          <p>Configurar Domínio</p>
        </button>
        <button @click="abrirModalIntegracoes()">
          <div class="icone">
            <Svgs nome="integracao" />
          </div>
          <p>Integrações</p>
        </button>
        <button @click="abrirModalCssPersonalizado">
          <div class="icone">
            <Svgs nome="codigo" />
          </div>
          <p>CSS Personalizado</p>
        </button>
        <button @click="abrirModalCompartilhar()" v-if="!state.siteCompartilhado">
          <div class="icone">
            <Svgs nome="usuarios" />
          </div>
          <p>Compartilhar</p>
        </button>
        <button @click="abrirModalExcluir(page._id)" v-if="!state.siteCompartilhado">
          <div class="icone">
            <Svgs nome="excluir" />
          </div>
          <p>Excluir esse site</p>
        </button>
      </div>
      <!-- Camadas -->
      <Camadas />
      <!-- Subir -->
      <div class="subir desktop">
        <button @click="abrirModalSubir()">
          <Svgs nome="subir" />
          <p>Subir para o ar</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref, onMounted, watch, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useStorePages, useStoreCriar, useStoreModal, useStorePerfil } from '@stores'
import Svgs from '@svgs'
import Camadas from '@/components/criar/partials/Camadas.vue'

const storePages = useStorePages()
const storeCriar = useStoreCriar()
const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const router = useRouter()

const { page } = storeToRefs(storePages)
const sectionEdicao = ref(null)
const acessoDesktop = ref(window.innerWidth >= 1024)

const state = reactive({
  mostrarEdicoesMobile: false,
  debounceOnline: null,
  camadasDesktop: [],
  camadasMobile: [],
  mostrarItens: false,
  siteCompartilhado: true,
  elementoSelecionado: '',
  indiceSecao: 0,
  dispositivo: 'desktop',
  idElementoMarcado: ''
})

function irParaHotlink(page) {
  const url = `https://unic.page/${page?.hotlink}`

  window.open(url, '_blank')
}

function irParaDominio(page) {
  const url = page.dominioRota ? `https://${page?.dominioPersonalizado}/${page?.dominioRota}` : `https://${page?.dominioPersonalizado}`

  window.open(url, '_blank')
}

function voltarAoInicio() {
  window.location.assign('/sites')
}

function mudarVisibilidadeSite() {
  clearTimeout(state.debounceOnline)
  state.debounceOnline = setTimeout(() => {
    storePages.page.online = !storePages.page.online
    alterarPageOnline()
  }, 200)
}

function alterarPageOnline() {
  const idPage = storePages.page._id
  const payload = {
    idPage,
    online: storePages.page.online
  }
  const status = storePages.page.online ? 'online' : 'offline'
  storePages.alterarDados(payload, false, `Seu site está ${status}.`)
}

function abrirModalInformacoes() {
  storeModal.abrirModal('editarSite', 'editar', 'Editar site')
}

function abrirModalFavicon() {
  storeModal.abrirModal('editarFavicon', 'favicon', 'Editar favicon')
}

function abrirModalExcluir(id) {
  storeModal.abrirModalEdicao('excluirSite', 'excluir', 'Excluir site', id)
}

function abrirModalDominio() {
  storeModal.abrirModal('configurarDominio', 'dominio', 'Configurar domínio')
}

function abrirModalIntegracoes() {
  storeModal.abrirModal('integracoes', 'integracao', 'Integrações')
}

function abrirModalCompartilhar() {
  storeModal.abrirModal('compartilhar', 'usuarios', 'Compartilhar')
}

function abrirModalCssPersonalizado() {
  storeCriar.mostrarModalCssPersonalizado = true
}

function abrirModalSubir() {
  storeModal.abrirModal('subirSite', 'subir', 'Subir alterações')
}

function verificarSeCompartilhado() {
  const idUsuarioLogado = storePerfil.usuario?._id
  const compartilhados = storePages.page?.compartilhadoCom || []

  return compartilhados.some((compartilhado) => compartilhado._id === idUsuarioLogado)
}

async function carregarPage() {
  const hotlink = router.currentRoute.value.params.hotlinkTemplate
  await storePages.receberPorHotlink(hotlink)
  document.title = storePages?.page?.metatags?.titulo + ' | UnicPages'
  state.siteCompartilhado = verificarSeCompartilhado()
}

function adicionarClasseBloqueado() {
  document.body.classList.add('bloqueado')
  storeCriar.liberadoCopiarElemento = false
}

function removerClasseBloqueado(event) {
  if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
    document.body.classList.remove('bloqueado')
    storeCriar.liberadoCopiarElemento = true
  }
}

function verificarTela() {
  acessoDesktop.value = window.innerWidth >= 1024
}

watch(
  () => state.mostrarEdicoesMobile,
  (novoValor) => {
    if (novoValor) {
      document.body.classList.add('bloqueado')
    } else {
      document.body.classList.remove('bloqueado')
    }
  }
)

onMounted(() => {
  window.addEventListener('resize', verificarTela)
  carregarPage()
})

onUnmounted(() => {
  window.removeEventListener('resize', verificarTela)
  document.body.classList.remove('bloqueado')
})
</script>

<style scoped>
section.edicao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-cinza-2);
  z-index: 10;
}

.edicoes::-webkit-scrollbar-thumb {
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
}

.edicoes::-webkit-scrollbar-track {
  background-color: transparent;
}

.edicoes {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.edicoes .topo {
  display: none;
}

.nome {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
}

.nome h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
  width: 100%;
}

.nome a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  word-break: break-all;
  transition: all 0.3s;
}

.nome a.dominio {
  color: var(--cor-azul);
}

.nome a.dominio:hover {
  color: var(--cor-verde);
}

.nome a:hover {
  color: var(--cor-branco);
}

.nome button.menu {
  display: none;
  position: absolute;
  right: 20px;
  width: 35px;
  height: 35px;
  background-color: transparent;
}

.nome button.menu span {
  position: absolute;
  background-color: var(--cor-branco);
  border-radius: 10px;
  height: 3px;
  transition: all 0.5s;
}

.nome button.menu:hover span {
  background-color: var(--cor-cinza-5);
}

.nome button.menu span.um {
  top: 10px;
  right: 0;
  width: 25px;
}

.nome button.menu span.dois {
  bottom: 10px;
  right: 0;
  width: 35px;
}

.nome button.menu.ativo span {
  background-color: var(--cor-vermelho);
}

.nome button.menu.ativo span.um {
  transform: rotate(45deg);
  width: 35px;
  top: 16px;
}

.nome button.menu.ativo span.dois {
  transform: rotate(-45deg);
  bottom: 16px;
}

.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.topo button.voltar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
}

.topo button.voltar:hover svg {
  fill: var(--cor-cinza-5);
}

.topo button.voltar:hover p {
  color: var(--cor-cinza-5);
}

.topo button.voltar svg {
  width: 11px;
  min-width: 11px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
  transform: rotate(-180deg);
  transition: all 0.3s;
}

.topo button.voltar p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.topo .status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 25px;
  border-radius: 50px;
  background-color: var(--cor-vermelho);
  position: relative;
  transition: all 0.5s;
}

.topo .status:hover {
  background-color: var(--cor-vermelho-escuro);
}

.topo .status span {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--cor-branco-fixo);
  border: 1px solid var(--cor-vermelho);
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.topo .status.ativo span {
  left: 55px;
  border: 1px solid var(--cor-azul);
}

.topo .status.ativo {
  background-color: var(--cor-azul);
}

.topo .status.ativo:hover {
  background-color: var(--cor-azul-escuro);
}

.topo .status.ativo h3 {
  color: var(--cor-branco-fixo);
}

.topo .status h3 {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-cinza-5);
  position: absolute;
  animation: fadeIn 0.5s linear;
  transition: all 0.3s;
}

.topo .status h3.um {
  color: var(--cor-branco-fixo);
  left: 15px;
}

.topo .status h3.dois {
  color: var(--cor-branco-fixo);
  right: 15px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(10px, 0);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

.opcoes {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 100%;
}

.opcoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: transparent;
  padding: 8px 0;
}

.opcoes button.breve {
  pointer-events: none;
  opacity: 0.5;
}

.opcoes button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.opcoes button .icone svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
}

.opcoes button:hover p {
  color: var(--cor-cinza-5);
}

.opcoes button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.camadas {
  display: flex;
  flex-direction: column;
  padding: 20px 0 100px 0;
  width: 100%;
  border-top: 2px solid var(--cor-cinza-3);
}

.camadas .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 0 20px;
}

.camadas .titulo .toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 25px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}

.camadas .titulo .toggle:hover {
  background-color: var(--cor-cinza-4);
}

.camadas .titulo .toggle span {
  width: 50%;
  height: 100%;
  background-color: var(--cor-cinza-5);
  border-radius: 50px;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.camadas .titulo .toggle.mobile span {
  left: 50%;
}

.camadas .titulo .toggle p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco-fixo);
  width: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.camadas .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.camadas .topo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 15px 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.camadas .topo:hover h4 {
  color: var(--cor-branco);
}

.camadas .topo svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-cinza-5);
  position: absolute;
  right: 20px;
  top: 15px;
}

.camadas .topo.ativo h4 span {
  color: var(--cor-branco);
}

.camadas .topo h4 span {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
  padding: 2px 0;
  text-align: center;
  min-width: 25px;
  width: 25px;
  margin: 0 5px 0 0;
  border-radius: 5px;
}

.camadas .topo h4 {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.camadas .elementos {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.camadas .elementos.mostrar {
  display: flex;
}

.camadas .elementos .itens {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.camadas .elementos .itens.mostrar {
  display: flex;
  opacity: 1;
  visibility: visible;
  animation: mostrarItens 0.3s linear;
}

@keyframes mostrarItens {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.camadas .elemento {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.camadas .elemento.ativo {
  background-color: var(--cor-cinza-3);
}

.camadas .elemento.ativo p {
  color: var(--cor-branco) !important;
}

.camadas .elemento.ativo svg {
  fill: var(--cor-branco);
}

.camadas .elemento:hover {
  background-color: var(--cor-cinza-3);
}

.camadas .elemento.oculto p {
  color: var(--cor-vermelho);
}

.camadas .elemento.oculto svg {
  fill: var(--cor-vermelho);
}

.camadas .elemento p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  text-transform: lowercase;
  overflow: hidden;
  transition: all 0.3s;
}

.camadas .elemento button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  margin-left: auto;
  transition: all 0.3s;
}

.camadas .elemento button:hover {
  background-color: var(--cor-cinza-3);
}

.camadas .elemento button:hover svg {
  fill: var(--cor-branco);
}

.camadas .elemento button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

.subir {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-cinza-2);
  padding: 20px;
}

.subir.mobile {
  display: none;
}

.subir.desktop {
  display: flex;
}

.subir button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--cor-azul);
  padding: 15px 20px;
  border-radius: 10px;
  transition: all 0.3s;
}

.subir button:hover {
  background-color: var(--cor-azul-escuro);
}

.subir button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.subir button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.edicao {
    min-height: auto;
    max-height: auto;
    height: auto;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;
  }

  section.edicao.aparecerMobile {
    border-radius: 10px;
  }

  .detalhe {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;
  }

  .edicoes {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 0;
    height: 0dvh;
    transition: all 0.5s;
  }

  .edicoes .topo {
    display: flex;
    padding: 10px 20px 10px 20px;
    background-color: var(--cor-cinza-3);
  }

  .edicoes.aparecer {
    height: 42dvh;
  }

  .nome button.menu {
    display: flex;
  }

  .parte {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 15px 15px 15px;
    margin: 0 0 0 0;
    border-bottom: none;
    border-top: 2px solid var(--cor-cinza-3);
  }

  .topo {
    display: none;
  }

  .nome {
    width: 100%;
    padding: 15px 20px 10px 20px;
  }

  .nome a {
    width: auto;
    margin: 0 5px 0 0;
  }

  .nome .info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .status {
    flex-direction: row-reverse;
    margin: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
      transform: translate(-10px, 0);
    }

    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }

  .opcoes {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    width: 100%;
  }

  .opcoes .botoes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    scrollbar-width: none;
    padding: 10px 20px 10px 20px;
  }

  .opcoes .botoes::-webkit-scrollbar {
    display: none;
  }

  .opcoes button svg {
    width: 12px;
    min-width: 12px;
  }

  .subir {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 20px;
  }

  .subir.mobile {
    display: flex;
  }

  .subir.desktop {
    display: none;
  }

  .subir button {
    padding: 15px 20px;
  }

  .subir button p {
    font-size: var(--f2);
  }

  .camadas {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    width: 100%;
    border-top: 2px solid var(--cor-cinza-3);
  }
}
</style>
