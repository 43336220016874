<template></template>

<script setup>
import pako from 'pako'
import { reactive, watch, nextTick } from 'vue'
import { useStoreAjustes, useStorePages } from '@stores'

const storeAjustes = useStoreAjustes()
const storePages = useStorePages()

const state = reactive({
  store: JSON.parse(JSON.stringify(storeAjustes.secoes)),
  atualizacaoPendente: false,
  timeout: null
})

const atualizarBackend = () => {
  if (!state.atualizacaoPendente) return

  state.atualizacaoPendente = false
  state.store = JSON.parse(JSON.stringify(storeAjustes.secoes))

  const payload = {
    idPage: storePages.page._id,
    rascunhoComprimidoDesktop: compressPayload(state.store.desktop),
    rascunhoComprimidoMobile: compressPayload(state.store.mobile)
  }

  storePages.alterarRascunho(payload, true)
}

const compressPayload = (content) => {
  const compressedString = String.fromCharCode(...pako.deflate(new TextEncoder().encode(JSON.stringify(content))))
  return btoa(compressedString)
}

watch(
  () => storeAjustes.secoes,
  () => {
    if (state.atualizacaoPendente) return

    state.atualizacaoPendente = true

    clearTimeout(state.timeout)

    state.timeout = setTimeout(() => {
      nextTick(atualizarBackend)
    }, 4000)
  },
  { deep: true }
)
</script>
