<template>
  <div id="criar">
    <Loading />

    <ModalSecoesDesktop />
    <ModalSecoesMobile />
    <ModalSecaoComIa />

    <ModalInformacoes />
    <ModalCompartilhar />

    <ModalExcluirSecaoDesktop />
    <ModalExcluirSecaoMobile />
    <ModalExcluirSite />

    <ModalDominio />
    <ModalReplicarParaMobile />
    <ModalFavicon />

    <ModalIntegrarFacebook />
    <ModalIntegrarGoogleTag />
    <ModalIntegrarCloakup />
    <ModalIntegracoes />

    <ModalSubir />
    <ModalCssPersonalizado />

    <SectionEdicao />
    <SectionAjustes />
    <SectionTela />

    <AutoLoad />
    <AutoSave />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'

import SectionAjustes from './partials/SectionAjustes.vue'
import SectionEdicao from './partials/SectionEdicao.vue'
import SectionTela from './partials/SectionTela.vue'

import AutoLoad from './partials/AutoLoad.vue'
import AutoSave from './partials/AutoSave.vue'

import Loading from './partials/Loading.vue'

import ModalDominio from './modals/Dominio.vue'
import ModalCssPersonalizado from './modals/CssPersonalizado.vue'
import ModalReplicarParaMobile from './modals/ReplicarParaMobile.vue'

import ModalSecoesMobile from './modals/SecoesMobile.vue'
import ModalSecoesDesktop from './modals/SecoesDesktop.vue'
import ModalSecaoComIa from './modals/SecaoComIa.vue'

import ModalSubir from './modals/Subir.vue'

import ModalIntegracoes from './modals/Integracoes.vue'
import ModalIntegrarGoogleTag from './modals/IntegrarGoogleTag.vue'
import ModalIntegrarFacebook from './modals/IntegrarFacebook.vue'
import ModalIntegrarCloakup from './modals/IntegrarCloakup.vue'

import ModalCompartilhar from './modals/Compartilhar.vue'
import ModalInformacoes from './modals/Informacoes.vue'
import ModalFavicon from './modals/Favicon.vue'

import ModalExcluirSecaoMobile from './modals/ExcluirSecaoMobile.vue'
import ModalExcluirSecaoDesktop from './modals/ExcluirSecaoDesktop.vue'
import ModalExcluirSite from '@components/app/sites/modals/ModalExcluirSite.vue'

const storePerfil = useStorePerfil()
const router = useRouter()

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

const bloquearSaida = (event) => {
  event.preventDefault()
  event.returnValue = ''
}

onMounted(() => {
  carregarPerfil()
  window.addEventListener('beforeunload', bloquearSaida)
})

onUnmounted(() => {
  window.removeEventListener('beforeunload', bloquearSaida)
})
</script>

<style>
body {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none !important;
}

body * {
  scrollbar-width: none;
}

body *::-webkit-scrollbar {
  display: none !important;
}
</style>
