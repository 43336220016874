import { defineStore } from 'pinia'

export const useStorePlano1Mobile = defineStore('storePlano1Mobile', {
  state: () => ({
    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 80,
        right: 30,
        bottom: 120,
        left: 30
      }
    },

    // Fundo
    fundoItens: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 60,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Fundo
    fundoToggle: {
      id: '',
      hidden: false,
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      maxHeight: 100,
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    // Fundo
    spanToggle: {
      id: '',
      hidden: false,
      backgroundColor: 'BEBEBE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 175,
      maxHeight: 80,
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'
    },

    // Icone da Tag
    iconeToggleUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    // Texto da Tag
    textoToggleUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Mensal'
    },

    // Icone da Tag
    iconeToggleDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    // Texto da Tag
    textoToggleDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Anual'
    },

    // Quadro Um

    fundoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 700,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      }
    },

    nomeIconeQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    nomeTituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Plano Silver'
    },

    nomeSubtituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a descrição do plano'
    },

    precoUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '0% OFF'
    },

    precoDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '9'
    },

    precoQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },

    precoSeisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado mensalmente'
    },

    precoSeteQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '15% OFF'
    },

    precoOitoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoNoveQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '10'
    },

    precoDezQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoOnzeQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ ano'
    },

    precoDozeQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 100 à vista'
    },

    botaoUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    botaoDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '333333',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    listaTituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 30,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Benefícios do plano'
    },

    listaParteUmQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteUmQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteDoisQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteDoisQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteTresQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteTresQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteCincoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteCincoQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    // Quadro Dois

    fundoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 700,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      }
    },

    nomeIconeQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    nomeTituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Plano Silver'
    },

    nomeSubtituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a descrição do plano'
    },

    precoUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '0% OFF'
    },

    precoDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '9'
    },

    precoQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },

    precoSeisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado mensalmente'
    },

    precoSeteQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '15% OFF'
    },

    precoOitoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoNoveQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '10'
    },

    precoDezQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoOnzeQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ ano'
    },

    precoDozeQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 100 à vista'
    },

    botaoUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    botaoDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '333333',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    listaTituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 30,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Benefícios do plano'
    },

    listaParteUmQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteUmQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteDoisQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteDoisQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteTresQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteTresQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteCincoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteCincoQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    // Quadro Tres

    fundoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 700,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      }
    },

    nomeIconeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    nomeTituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Plano Silver'
    },

    nomeSubtituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a descrição do plano'
    },

    precoUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '0% OFF'
    },

    precoDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '9'
    },

    precoQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },

    precoSeisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado mensalmente'
    },

    precoSeteQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '15% OFF'
    },

    precoOitoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoNoveQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '10'
    },

    precoDezQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoOnzeQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ ano'
    },

    precoDozeQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 100 à vista'
    },

    botaoUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    botaoDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '333333',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    listaTituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 30,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Benefícios do plano'
    },

    listaParteUmQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteUmQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteDoisQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteDoisQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteTresQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteTresQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteCincoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteCincoQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    // Quadro Quatro

    fundoQuadroQuatro: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 700,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      }
    },

    nomeIconeQuadroQuatro: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    nomeTituloQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Plano Silver'
    },

    nomeSubtituloQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a descrição do plano'
    },

    precoUmQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '0% OFF'
    },

    precoDoisQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoTresQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '9'
    },

    precoQuatroQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoCincoQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },

    precoSeisQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado mensalmente'
    },

    precoSeteQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '15% OFF'
    },

    precoOitoQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoNoveQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '10'
    },

    precoDezQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoOnzeQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ ano'
    },

    precoDozeQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 100 à vista'
    },

    botaoUmQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    botaoDoisQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '333333',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    listaTituloQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 30,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Benefícios do plano'
    },

    listaParteUmQuadroQuatro: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteUmQuadroQuatro: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteUmQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteUmQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteDoisQuadroQuatro: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteDoisQuadroQuatro: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteDoisQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteDoisQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteTresQuadroQuatro: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteTresQuadroQuatro: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteTresQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteTresQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteQuatroQuadroQuatro: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteQuatroQuadroQuatro: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteQuatroQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteQuatroQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    },

    listaParteCincoQuadroQuatro: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteCincoQuadroQuatro: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoUmParteCincoQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    textoDoisParteCincoQuadroQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'UnicPages'
    }
  })
})
